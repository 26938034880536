import React, { useEffect } from 'react';
import { actions } from './reducers';
import setup from './setup';

const { indexedDBName } = setup;

type Dispatch = React.Dispatch<{ type: string, payload: IDBDatabase }>

export function useLocalDB(dispatchLocalDB: Dispatch) {
  // setup DB
  useEffect(() => {
    const request = indexedDB.open(indexedDBName, 8);
    request.onerror = () => {
      console.log('Error', 'local-db-error');
    };
  
    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      dispatchLocalDB(actions.openDB(db));
      db.onversionchange = function() {
        db.close();
        document.location.reload();
      };
    };

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      console.log(db.objectStoreNames);
  
      if (db.objectStoreNames.contains('users')) db.deleteObjectStore('users');
      if (db.objectStoreNames.contains('env')) db.deleteObjectStore('env');
      if (db.objectStoreNames.contains('notes')) db.deleteObjectStore('notes');

      // environment local store
      db.createObjectStore('env', { keyPath: 'name' });
      // envStore.createIndex('value', 'value', { unique: false });
      // notes local store
      const notesStore = db.createObjectStore('notes', { keyPath: 'id' });
      notesStore.createIndex('user', 'user', { unique: false });
      notesStore.createIndex('datetime', 'datetime', { unique: false });
      // notesStore.createIndex('header', 'header', { unique: false });
      // notesStore.createIndex('body', 'body', { unique: false });
    };
  },[dispatchLocalDB]);
}
