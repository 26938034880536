import React from 'react';
import { Overlay } from './Overlay';
import { Editable } from './Editable';
import './Editable.css';

type Item = {
  id: number;
  header: string;
  body: string;
}

type ExpandedItemProps = {
  item: Item | null | undefined;
  token: string;
  dispatchNotesList: React.Dispatch<{ type: string, payload: Item | number}>
  dispatchExpand: React.Dispatch<{ type: string, payload: number | null }>
};

type Children = {
  children: JSX.Element | JSX.Element[];
};

function Wrapper({ children }: Children) {
  return (
    <div className='editable-wrapper'>
      {children}
    </div>
  );
}

export function ExpandedItem({ item, token, dispatchExpand, dispatchNotesList }: ExpandedItemProps) {
  if (!item) return null;
  return (
    <>
      <Overlay />
      <Wrapper>
        <Editable token={token} initialItem={item} dispatchExpand={dispatchExpand} dispatchNotesList={dispatchNotesList} />
      </Wrapper>
    </>
  );
}