import React from 'react';
import { Add, SearchOutlined, SearchOffOutlined } from '@mui/icons-material';
import { actions } from '../reducers';
import './Toolbar.css';

type User = {
  id: number,
  name: string,
} | null;

type ToolbarProps = {
  user: User,
  search: string,
  deleted: boolean,
  numColumns: number,
  onLine: boolean,
  dispatchExpand: React.Dispatch<{
    type: string;
    payload: number | null;
  }>,
  dispatchSearch: React.Dispatch<{
    type: string;
    payload: string;
  }>
  dispatchMenu: React.Dispatch<{
    type: string;
    payload: boolean;
  }>
};

export function Toolbar({ user, search, deleted, numColumns, onLine, dispatchExpand, dispatchSearch, dispatchMenu }: ToolbarProps) {

  const createNote = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    dispatchExpand(actions.expandNote(0));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatchSearch(actions.change(e.target.value));
  };

  // console.log(search);


  if(!user) return null;

  return (
    <div className='toolbar'>
      <div className='toolbar-left-side'>
        <button
          className={onLine ? 'avatar online' : 'avatar offline'}
          type='button'
          title='add note'
          onClick={deleted || onLine === false ? () => true : (e) => createNote(e)}
        >
          <Add />
        </button>
      </div>
      <div className='search-wrapper'>
        <button
          className={`search-button ${search && 'active'}`}
          onClick={() => dispatchSearch(actions.change(''))}
        >
          {search ? <SearchOffOutlined /> : <SearchOutlined />}
        </button>

        <input
          className='search-input'
          autoComplete='off'
          placeholder={numColumns === 1 ? 'Search' : 'Search your notes'}
          value={search}
          name="q"
          type='text'
          onChange={handleSearch}
        />
      </div>
      <div className='user-wrapper'>
        <button
          className={onLine ? 'avatar extra online' : 'avatar extra offline'}
          type='button'
          title={user.name}
          onClick={() => dispatchMenu(actions.toggle())}
        >
          {user.name.substring(0, 1).toUpperCase()}
        </button>
      </div>
    </div>
  );
}
