import setup from '../setup';
import { actions } from '../reducers';

const { notesAPI } = setup;

type Note = {
  id: number,
  header: string,
  body: string,
};

type Note1 = {
  id: number,
  header?: string,
  body?: string,
};

// calculate number of columns
export function calcColsNum() {
  return Math.floor((document.body.clientWidth - setup.gap) / (setup.noteWidth + setup.gap));
}

export async function getNotes(token: string, from: number, num: number, search = '', deleted = false) {
  try {
    const result = await fetch(`${notesAPI}?token=${token}&from=${from}&num=${num}&search=${search}${deleted ? '&deleted' : ''}`);
    const array = await result.json();
    return array;
  } catch (err) {
    return { error: true, message: (err as Error).message };
  }
}

export async function getNoteById(token: string, noteId: number) {
  try {
    const result = await fetch(`${notesAPI}?token=${token}&noteId=${noteId}`);
    const obj = await result.json();
    return obj;
  } catch (err) {
    return { error: true, message: (err as Error).message };
  }
}

export async function editNoteDB(token: string, note: Note1, update = false) {
  try {
    const response = await fetch(`${notesAPI}?token=${token}`, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...note, update}),
    });
    const result = await response.json();
    if (!result.success) throw new Error('db_error');
    return result;
  } catch (err) {
    return { error: true, message: (err as Error).message };
  }
}

export const handleResize = (
  isResized: React.MutableRefObject<boolean>,
  dispatchLayoutAction: React.Dispatch<{ type: string; payload: number;}>
) => () => {
  const setResized = () => isResized.current = true;
  window.addEventListener('resize', setResized);
  const interval = setInterval(() => {
    if (isResized.current) {
      isResized.current = false;
      dispatchLayoutAction(actions.resizeScreen(calcColsNum()));
    }
  }, 1000);
  return () => {
    clearInterval(interval);
    window.removeEventListener('resize', setResized);
  };
};

export async function deleteNoteDB(token: string, noteId: number, undelete = false) {
  try {
    const response = await fetch(`${notesAPI}?token=${token}`, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ noteId, undelete }),
    });
    const result = await response.json();
    if (!result.success) throw new Error('db_error');
    return result;
  } catch (err) {
    return { error: true, message: (err as Error).message };
  }
}

export const raiseNote = (
  token: string,
  noteId: number,
  dispatchNotesList: React.Dispatch<{ type: string, payload: Note | number}>,
) => async (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  e.preventDefault();
  try {
    // update note in db
    const result = await editNoteDB(token, { id: noteId }, true);
    if (result.error) throw new Error('db_error');
    // raise note on client
    if (result.success === 'updated') dispatchNotesList(actions.raiseNote(noteId));
  } catch (err: any) {
    console.log(err.message);
  }
};

export const deleteNote = (
  token: string,
  noteId: number,
  dispatchNotesList: React.Dispatch<{ type: string, payload: Note | number}>,
  dispatchExpand: React.Dispatch<{ type: string, payload: number | null }>,
) => async (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  e.preventDefault();
  try {
    // if note was not saved to db, just unexpand it
    if (noteId === 0) {
      dispatchExpand(actions.unExpandNote());
      return;
    }
    // delete note from db
    const result = await deleteNoteDB(token, noteId);
    if (result.error) throw new Error('db_error');
    // delete note from client
    if (result.success === 'deleted') dispatchNotesList(actions.deleteNote(noteId));
  } catch (err: any) {
    console.log(err.message);
  }
};

export const undeleteNote = (
  token: string,
  noteId: number,
  dispatchNotesList: React.Dispatch<{ type: string, payload: Note | number}>,
) => async (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  e.preventDefault();
  try {
    // undelete note from db
    const result = await deleteNoteDB(token, noteId, true);
    if (result.error) throw new Error('db_error');
    // delete note from clients deleted list
    if (result.success === 'deleted') dispatchNotesList(actions.deleteNote(noteId));
  } catch (err: any) {
    console.log(err.message);
  }
};

export const saveNote = (
  token: string,
  note: Note,
  changed: boolean,
  dispatchNotesList: React.Dispatch<{ type: string, payload: Note | number}>,
  dispatchExpand: React.Dispatch<{ type: string, payload: number | null }>,
  // dispatchEditableItem: React.Dispatch<{ type: string, payload: Note }>,
  dispatchId: React.Dispatch<{ type: string, payload: number }>,
) => async (e: React.MouseEvent<HTMLElement>) => {
  try {
    e.preventDefault();
    e.stopPropagation();
    if (!changed) return;
    // console.log('note', note);
    if (note.header === '' && note.body === '' && note.id === 0) {
      dispatchExpand(actions.unExpandNote());
      return;
    }
    // save note to db
    const result = await editNoteDB(token, note);
    if (result.error) throw new Error('db_error');
    // save note on client
    if (result.success === 'inserted') {
      dispatchNotesList(actions.addNote({ ...note, id: result.id }));
      // dispatchEditableItem({ type:'change', payload: { ...note, id: result.id } });
      dispatchId(actions.changeNoteId(result.id));
    }
    else dispatchNotesList(actions.editNote(note));
    // unload component
    // dispatchExpand({ type:'unexpand', payload: null });
  } catch (err: any) {
    console.log(err.message);
  }
};

export const saveAndCloseNote = (
  token: string,
  note: Note,
  changed: boolean,
  dispatchNotesList: React.Dispatch<{ type: string, payload: Note | number}>,
  dispatchExpand: React.Dispatch<{ type: string, payload: number | null }>,
  // dispatchEditableItem: React.Dispatch<{ type: string, payload: Note }>,
  dispatchId: React.Dispatch<{ type: string, payload: number }>,
) => async (e: React.MouseEvent<HTMLElement>) =>{
  try {
    // saveNote(token, note, dispatchNotesList, dispatchExpand, dispatchEditableItem)(e);
    saveNote(token, note, changed, dispatchNotesList, dispatchExpand, dispatchId)(e);
    // unload component
    dispatchExpand(actions.unExpandNote());
  } catch (err: any) {
    console.log(err.message);
  }
};

export const expandNote = (
  id: number, dispatchExpand: React.Dispatch<{ type: string, payload: number | null }>
) => async (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  e.preventDefault();
  try {
    // if (e.detail === 2)
    dispatchExpand(actions.expandNote(id));
  } catch (err: any) {
    console.log(err.message);
  }
};

export const setActiveNote = (
  id: number,
  dispatchActive: React.Dispatch<{ type: string, payload: number | null }>,
) => async (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  e.preventDefault();
  try {
    // if (e.detail === 2)
    dispatchActive(actions.activateNote(id));
  } catch (err: any) {
    console.log(err.message);
  }
};