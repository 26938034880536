import { useEffect, useRef } from 'react';
import { actions } from '../reducers';
import { getNotes } from './notesUtils';

import setup from '../setup';

const { fetchArraySize } = setup;

type NoteItem = {
  id: number;
  header: string;
  body: string;
}


export function useFetchNotes(
  dispatchNotesList: React.Dispatch<{ type: string, payload: NoteItem[] }>,
  dispatchShortList: React.Dispatch<{ type: string, payload: NoteItem[] | string}>,
  notesList: NoteItem[],
  token: string,
  userId: number,
  search = '',
  deleted = false,
  onLine = true,
  localDB: IDBDatabase | null,
) {
  // checkList ensures that new notes would append only once
  const checkList: React.MutableRefObject<number[]> = useRef([]);

  useEffect(() => {
    async function fetchData(from: number, num: number, search = '', deleted = false) {
      const array = await getNotes(token, from, num, search, deleted);
      if (array.error) {
        console.log(`error: ${array.message}`);
        return;
      }
      if (array.length === 0) return;
      if (checkList.current.includes(from)) return;
      // console.log('dispatchNotesList', array);
      if (from === 0) dispatchNotesList(actions.resetNotes(array));
      else dispatchNotesList(actions.appendNotes(array));
      checkList.current.push(from);
      if (array.length < num) return;
      await fetchData(from + num, num, search);
    }

    async function fetchLocal() {
      return new Promise((resolve, reject) => {
        if (localDB === null) return;
        if (deleted === true && onLine === false) {
          dispatchNotesList(actions.resetNotes([]));
          return;
        }
        
        const trans = localDB.transaction('notes', 'readonly');
        const objectStore = trans.objectStore('notes');
        const request = objectStore.index('datetime').getAll();
        request.onsuccess = () => {
          const array = request.result;
          if (array.length !== 0) {
            dispatchNotesList(actions.resetNotes(array.filter((x) => x.user === userId).reverse()));
          }
          resolve(true);
        };
      });
     }

    (async function() {
      // first fetch data from local db
      await fetchLocal();
      // if offline, then return
      if (onLine === false) return;
      // fetch data from remote
      // if connection is poor user sees local dump
      checkList.current = [];
      await fetchData(0, fetchArraySize, '', deleted);
    })();
  }, [token, deleted, dispatchNotesList, onLine, localDB, userId]);

  // filter notes by search string
  useEffect(() => {
    console.log('useNotesList', notesList);
    dispatchShortList(actions.resetNotes(notesList));
    // dispatchShortList(actions.appendNotes(notesList));
    dispatchShortList(actions.searchNotes(search as string));
  }, [dispatchShortList, search, notesList]);
}

