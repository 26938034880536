import React from 'react';
import './Overlay.css';


export function Overlay() {
  return (
    <div
      className='overlay'
      style={{ minHeight: window.innerHeight }}
      // onScroll={(e: any) => {
      //   e.preventDefault();
      //   e.stopPropagation();
      // }}
    />
  );
}