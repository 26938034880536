// usage:
// <Masonry numColumns={5} colWidth={240} gap={20)>
//   {/* array of JSX items */}
// </Masonry>

import React from 'react';

type Props = {
  children?: JSX.Element[] | [];
  colWidth?: number;
  gap?: number;
  numColumns?: number;
}

function Column({ children, colWidth, gap, numColumns } : Props) {
  const style: React.CSSProperties = {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: numColumns === 1 ? '100%' : colWidth,
    gap,
    boxSizing: 'border-box',
  };
  return (
    <div style={style}>{children}</div>
  );
}

export default function Masonry({ children = [], numColumns = 1, colWidth = 200, gap = 10} : Props) {
  const columnsList = (new Array(numColumns)).fill(0).map((x, i) => (
    <Column
      key={i}
      colWidth={colWidth}
      gap={gap}
      numColumns={numColumns}
    >
      {children.filter((item, index) => index % numColumns === i)}
    </Column>
  ));

  const style: React.CSSProperties = {
    margin: '0 auto',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap,
    width: numColumns === 1 ? '95%' : numColumns * (colWidth + gap) - gap,
    boxSizing: 'border-box',
  };

  return (
    <div style={style}>{columnsList}</div>
  );
}