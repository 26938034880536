const setup = () => ({
  cropAt: 265,
  noteWidth: 240,
  colWidth: 240,
  gap: 20,
  numColumns: 1,
  indexedDBName: 'notes-local-db',
  notesAPI: 'https://notes-api.inclouds.org/api/notes',
  usersAPI: 'https://notes-api.inclouds.org/api/users',
  // notesAPI: 'https://api.notes.inclouds.org/api/notes',
  // usersAPI: 'https://api.notes.inclouds.org/api/users',
  connectInterval: 50000, // keep connection timeout
  fetchArraySize: 1000, // number of notes in single fetch from server
});

export default setup();