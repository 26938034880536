import React, { useState } from 'react';
import './Login.css';
import { signUpForm, resetForm, loginByPassword } from './uUtils';
// ryabinin@svetilo.ru : otgdo5qv07

type User = {
  id: number,
  name: string,
  token: string,
}

type LoginProps = {
  // login: (name: string, password: string) => Promise<void>,
  dispatchSetUser: React.Dispatch<{ type: string; payload: User | null | undefined; }>,
  dispatchAuth: React.Dispatch<{ type: string, payload: number | null }>,
  localDB: IDBDatabase | null
};

export function Login ({ dispatchSetUser, localDB, dispatchAuth }: LoginProps) {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const handleChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (field) {
      case 'name': setUserName(e.target.value); break;
      case 'psw': setPassword(e.target.value); break;
      default: return;
    }
  };

  async function loginUser(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      e.stopPropagation();
      await loginByPassword(dispatchSetUser, localDB, userName, password);
    } catch (err: any) {
      console.log(err.message);
      switch (err.message) {
        case 'no_such_user': setMessage('Invalid email or password'); break;
        default: setMessage('Try again please');
      }
    }
  }

  const [message, setMessage] = useState('');
  
  return (
    <div className='login'>
      <div className="wrapper">
        <div className="block-request">
          <h2>Log in to Notes</h2>
          <form
            className='form'
            onSubmit={loginUser}
          >
            <div className="input text-input">
              <div className="fieldset">
                <span className="title">Email address</span>
                <input
                  className='text'
                  required={true}
                  title='Your email'
                  placeholder='name@example.com'
                  name='name'
                  value={userName}
                  type='email'
                  onChange={handleChange('name')}
                />
              </div>
              <div className="fieldset">
                <span className="title">Password</span>
                <input
                  className='text'
                  required={true}
                  title='Your password'
                  placeholder=''
                  name='psw'
                  value={password}
                  type='password'
                  onChange={handleChange('psw')}
                />
              </div>
            </div>
            <div className="input block-submit">
              <input
                className='submit request-submit'
                name='request-submit'
                value='Log in'
                type='submit'
              />
              <div className='extra-actions'>
                <button
                  className="register"
                  title="Sign up"
                  onClick={signUpForm(dispatchAuth)}
                >Sign up for free</button>
                <button
                  className="forget"
                  title="forget password"
                  onClick={resetForm(dispatchAuth)}
                >Forget password?</button>
              </div>
              <div className='error-message'>
                {message}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}