import React, { useState } from 'react';
import './Login.css';
import { signUpForm, logInForm, makeKey } from './uUtils';
import { actions } from '../reducers';
// import { signup } from './uUtils';

// Message
// We just sent a message to the email you provided with a link to reset your password.
// Please check your inbox and follow the instructions in the email.

type ForgetProps = {
  dispatchAuth: React.Dispatch<{ type: string, payload: number | null }>,
};

export function Forget ({ dispatchAuth }: ForgetProps) {
  const [userName, setUserName] = useState('');
  // const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (field) {
      case 'name': setUserName(e.target.value); break;
      // case 'psw': setPassword(e.target.value); break;
      default: return;
    }
  };

  const keyGen = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setMessage('');
      await makeKey(userName);
      dispatchAuth(actions.showLogInUser(4));
    } catch (err: any) {
      console.log(err.message);
      switch (err.message) {
        case 'duplicate_user': setMessage('An account with this email already exists'); break;
        default: setMessage('Try again please');
      }
    }
  };
  
  return (
    <div className='login'>
      <div className="wrapper">
        <div className="block-request">
          <h2>Request password reset</h2>
          <form
            className='form'
            onSubmit={keyGen}
          >
            <div className="input text-input">
              <div className="fieldset">
                <span className="title">Email address</span>
                <input
                  className='text'
                  required={true}
                  title='Your email'
                  placeholder='name@example.com'
                  name='name'
                  value={userName}
                  type='email'
                  onChange={handleChange('name')}
                />
              </div>
            </div>
            <div className="input block-submit">
              <input
                className='submit request-submit'
                name='request-submit'
                value='Request reset'
                type='submit'
              />
              <div className='extra-actions'>
                <button
                  className="register"
                  title="Sign up"
                  onClick={logInForm(dispatchAuth)}
                >Log in</button>
                <button
                  className="register"
                  title="Sign up"
                  onClick={signUpForm(dispatchAuth)}
                >Sign up for free</button>
              </div>
              <div className='error-message'>
                {message}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}