import Masonry from './Masonry';
import setup from '../setup';
import { Note } from './Note';
import { numbersReducer, notesListReducer, stringReducer, booleanReducer } from '../reducers';
import './Note.css';
import React, { useReducer, useEffect } from 'react';
import { useFetchNotes } from './useFetchNotes';
import { useDump } from './useDump';
import { useScreenResize } from '../useScreenResize';
import { ExpandedItem } from './ExpandedItem';
import { Toolbar } from './Toolbar';
import { Menu } from './Menu';
import { calcColsNum } from './notesUtils';

type NoteItem = {
  id: number;
  header: string;
  body: string;
}

type User = {
  id: number,
  name: string,
  token: string,
};

type AreaOfUserProps = {
  user: User,
  localDB: IDBDatabase | null,
  onLine: boolean,
  dispatchSetUser: React.Dispatch<{ type: string, payload: User | null | undefined }>
  // logout: () => void,
};

// const notesList :Note[] = [];

export function AreaOfUser({ user, dispatchSetUser, localDB, onLine }: AreaOfUserProps) {

  // TRACK STATES
  // page layout state
  // const [layout, dispatchLayoutAction] = useReducer(layoutReducer, { ...setup, numColumns: calcColsNum() });
  const [numColumns, dispatchColumns] = useReducer(numbersReducer, calcColsNum());
  // notes list state
  const [notesList, dispatchNotesList] = useReducer(notesListReducer, []);

  const [shortlist, dispatchShortList] = useReducer(notesListReducer, []);
  // track id of expanded note
  const [expanded, dispatchExpand] = useReducer(numbersReducer, null);
  // track id of active note
  const [active, dispatchActive] = useReducer(numbersReducer, null);
  // search string
  const [search, dispatchSearch] = useReducer(stringReducer, '');
  // menu: true or false
  const [menu, dispatchMenu] = useReducer(booleanReducer, false);

  const [deleted, dispatchDeleted] = useReducer(booleanReducer, false);

  // const [dump, dispatchDump] = useReducer(booleanReducer, true);

  // const notes = useRef([] as NoteItem[]);


  const { colWidth, gap, cropAt } = setup;
  const padding = 60 + (numColumns === 1 ? gap/2 : gap);

  // handles screen resizing
  useScreenResize(dispatchColumns);

  // fetch notes from remote or local db
  useFetchNotes(dispatchNotesList, dispatchShortList, notesList, user.token, user.id, search, deleted, onLine, localDB);

  // dump notesList
  useDump(notesList, deleted, onLine, localDB);

  let expandedItem: NoteItem | null | undefined = null;
  switch (true) {
    case expanded === null: expandedItem = null; break;
    case expanded === 0: expandedItem = { id: 0, header: '', body: '' }; break;
    default: expandedItem = shortlist.find((x: NoteItem) => x.id === expanded);
  }
  // remove scrolling while edit note
  useEffect(() => {
    if (expandedItem) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'absolute';
      document.body.style.inset = '0';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.position = 'relative';
      document.body.style.inset = 'auto';
    }
  }, [expandedItem]);

  const itemsList = shortlist.map((item: NoteItem) => (
    <Note
      onLine={onLine}
      key={item.id}
      note={item}
      search={search}
      cropAt={cropAt}
      active={item.id === active}
      deleted={deleted}
      token={user.token}
      dispatchNotesList={dispatchNotesList}
      dispatchExpand={dispatchExpand}
      dispatchActive={dispatchActive}
    />
  ));

  if (numColumns === null) return null;

  return (
    <div className={`layout-${numColumns}-column`} style={{ paddingTop: padding, paddingBottom: gap }}>
      <Toolbar
        onLine={onLine}
        user={user}
        search={search}
        deleted={deleted}
        numColumns={numColumns}
        dispatchExpand={dispatchExpand}
        dispatchSearch={dispatchSearch}
        dispatchMenu={dispatchMenu}
      />
      <Menu
        onLine={onLine}
        user={user}
        isActive={menu}
        deleted={deleted}
        localDB={localDB}
        dispatchSetUser={dispatchSetUser}
        dispatchDeleted={dispatchDeleted}
        dispatchMenu={dispatchMenu}
      />
      <ExpandedItem
        token={user.token}
        item={expandedItem}
        dispatchNotesList={dispatchNotesList}
        dispatchExpand={dispatchExpand}
      />
      <Masonry
        numColumns={numColumns}
        colWidth={colWidth}
        gap={numColumns === 1 ? gap/2 : gap}
      >
        {itemsList}
      </Masonry>
    </div>
  );

}