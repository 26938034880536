import React from 'react';
import './Login.css';
// import { signup } from './uUtils';

// Message
// We just sent a message to the email you provided with a link to reset your password.
// Please check your inbox and follow the instructions in the email.

type MessageWrapperProps = {
  children: JSX.Element,
}

export function MessageWrapper ({ children }: MessageWrapperProps) {

  return (
    <div className='login'>
      <div className="wrapper">
        <div className="check-inbox">
          {children}
        </div>
      </div>
    </div>
  );
}