import React from 'react';
import { LogoutOutlined, EmojiObjects, EmojiObjectsOutlined } from '@mui/icons-material';
import { actions } from '../reducers';
import { logout } from '../Users/uUtils';
import './Menu.css';

type User = {
  id: number,
  name: string,
  token: string,
};

type MenuProps = {
  onLine: boolean,
  user: User,
  isActive: boolean,
  deleted: boolean,
  localDB: IDBDatabase | null,
  // logout: () => void,
  dispatchSetUser: React.Dispatch<{ type: string, payload: User | null | undefined }>
  dispatchDeleted: React.Dispatch<{ type: string; payload: boolean; }>
  dispatchMenu: React.Dispatch<{ type: string; payload: boolean; }>
}

export function Menu({ onLine, user, isActive, deleted, localDB, dispatchSetUser, dispatchDeleted, dispatchMenu }: MenuProps) {
  if (!isActive) return null;

  const deletedElement = deleted
    ? (
      <div>
        <button
          className='show-deleted-button'
          onClick={() => {
            dispatchDeleted(actions.toggle());
            dispatchMenu(actions.toggle());
          }}>
          <EmojiObjectsOutlined />
          <span>Show active notes</span>
        </button>
      </div>
    ) : (
      <div>
        <button
          className='show-deleted-button'
          onClick={() => {
            dispatchDeleted(actions.toggle());
            dispatchMenu(actions.toggle());
          }}>
          <EmojiObjects />
          <span>Show deleted notes</span>
        </button>
      </div>
    );
  return (
    <div className='menu-block'>
      <div>{user.name}</div>
      <div>
        <button className='logout-button' onClick={onLine === false ? () => true : () => logout(dispatchSetUser, localDB)}>
          <LogoutOutlined />
          <span>Log out current user</span>
        </button>
      </div>
      {deletedElement}
    </div>
  );
}