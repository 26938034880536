import { useEffect, useRef } from 'react';
// import { listReducer } from './reducers';
import { calcColsNum } from './Notes/notesUtils';
import { actions } from './reducers';

type DispatchArgs = {
  type: string;
  payload: number | null;
}

type Dispatch = ({ type, payload }: DispatchArgs) => void;



export function useScreenResize(dispatch: Dispatch) {
  const isResized = useRef(false);
  const setResized = () => isResized.current = true;
  useEffect(() => {
    window.addEventListener('resize', setResized);
    const interval = setInterval(() => {
      // console.log('timer');
      if (isResized.current) {
        isResized.current = false;
        dispatch(actions.resizeScreen(calcColsNum()));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', setResized);
    };
  }, [dispatch]);
}

