import React, { useEffect, useRef } from 'react';
import { Highlight } from './Highlight';
import { actions } from '../reducers';


type Note = {
  id: number;
  header: string;
  body: string;
}

type NoteContentProps = {
  initData: Note;
  cropAt: number;
  search: string;
  isCropped: boolean,
  dispatchCropped: React.Dispatch<{ type: string, payload: boolean }>
};

export function NoteContent({ initData, cropAt = 200, search, isCropped, dispatchCropped } : NoteContentProps) {

  const ref = useRef<HTMLDivElement>(null);
  const { header, body } = initData;

  // crop note text overflow
  useEffect(() => {
    const scroll = ref.current?.scrollHeight || 0;
    if (scroll > cropAt) dispatchCropped(actions.cropNote());
  }, [cropAt, dispatchCropped]);

  const style: { maxHeight?: number, color?: string } = {};
  if (isCropped) style.maxHeight = cropAt;
  // if (expanded) style.color = 'transparent';
  
  return (
    <div className="note-content">
      <div
        ref={ref}
        className={isCropped ? 'note-item cropped' : 'note-item'}
        style={style}
      >
        <h1 className="note-header">
          {/* <span>{id}</span> */}
          <Highlight text={header} search={search} />
        </h1>
        <Highlight text={body} search={search} />
      </div>
    </div>
  );
}