import React, { useEffect } from 'react';
import { logout } from './Users/uUtils';
import { actions } from './reducers';
import setup from './setup';

const { connectInterval } = setup;

type User = {
  id: number,
  name: string,
  token: string,
};

type Dispatch = React.Dispatch<{ type: string, payload: User | null | undefined }>

async function setUser(dispatchSetUser: Dispatch, db: IDBDatabase | null) {
  console.log('check user');
  if (db === null) {
    dispatchSetUser(actions.loginUser(undefined));
    return;
  }
  const transaction = db.transaction('env', 'readonly');
  const objectStore = transaction.objectStore('env');
  const request = objectStore.get('user');
  request.onsuccess = () => {
    const result = request.result;
    if (result) {
      console.log('user is cached');
      dispatchSetUser(actions.loginUser(result.value));
      // cursor.continue();
    } else {
      console.log('user was lost');
      logout(dispatchSetUser, db);
      dispatchSetUser(actions.loginUser(null));
    }
  };

}

export function useKeepConnected(dispatchSetUser: Dispatch, localDB: IDBDatabase | null) {
  useEffect(() => {
    setUser(dispatchSetUser, localDB);
    const interval = setInterval(() => {
      setUser(dispatchSetUser, localDB);
    }, connectInterval);
    return () => {
      clearInterval(interval);
    };
  }, [dispatchSetUser, localDB]);
}

// export function useKeepConnected(dispatch: Dispatch) {
//   useEffect(() => {
//     const userToken = localStorage.getItem('token') || null;
//     if(userToken) fetchUserByToken(dispatch)(userToken);
//     const interval = setInterval(() => {
//       // console.log('timer');
//       const token = localStorage.getItem('token') || null;
//       if(!token) {
//         logout(dispatch)();
//         return;
//       }
//       fetchUserByToken(dispatch)(token as string);
//     }, connectInterval);
//     return () => {
//       clearInterval(interval);
//     };
//   }, [dispatch]);
// }

