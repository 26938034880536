import React, { useReducer } from 'react';
import { numbersReducer } from '../reducers';
import { Login } from './Login';
import { SignUp } from './SignUp';
import { Forget } from './Forget';
import { Reset } from './Reset';
import { MessageWrapper } from './MessageWrapper';
import { useReset } from './uUtils';

type User = {
  id: number,
  name: string,
  token: string,
}

type AuthProps = {
  name: string | null,
  resetKey: string | null,
  localDB: IDBDatabase | null,
  dispatchSetUser: React.Dispatch<{ type: string; payload: User | null | undefined; }>,
  // login: (name: string, password: string) => Promise<void>,
};

export function Auth ({ name, resetKey, localDB, dispatchSetUser }: AuthProps) {
  const [authStage, dispatchAuth] = useReducer(numbersReducer, 0);

  useReset(name, resetKey, dispatchAuth); 

  // define messages for password reset steps
  const messageCheckEmail = (
    <>
      <h2>Check your inbox</h2>
      <div>
        We just sent a message to the email you provided with a link to reset your password. Please check your inbox and follow the instructions in the email.
      </div>
    </>
  );    
  
  const messageKeyError = (
    <>
      <h2>Password reset error</h2>
      <div>
        Your reset key is incorrect or expired. Please, <a href="/">try again</a>
      </div>
    </>
  ); 

  const messageResetSuccess = (
    <>
      <h2>Password was reset</h2>
      <div>
        Your reset was reset. Please, <a href="/">log in</a> with new password
      </div>
    </>
  ); 


  switch (true) {
    // case name !== null && resetKey !== null: return null;
    case authStage === 1: return <Login dispatchSetUser={dispatchSetUser} dispatchAuth={dispatchAuth} localDB={localDB} />;
    case authStage === 2: return <SignUp dispatchAuth={dispatchAuth} />;
    case authStage === 3: return <Forget dispatchAuth={dispatchAuth} />;
    case authStage === 4: return <MessageWrapper>{messageCheckEmail}</MessageWrapper>;
    case authStage === 5: return <MessageWrapper>{messageKeyError}</MessageWrapper>;
    case authStage === 6: return <Reset resetKey={resetKey as string} name={name as string} dispatchAuth={dispatchAuth} />;
    case authStage === 7: return <MessageWrapper>{messageResetSuccess}</MessageWrapper>;
    default: return null;
  }
  
}