import { useEffect} from 'react';

type NoteItem = {
  id: number;
  header: string;
  body: string;
}


export function useDump(
  notesList: NoteItem[],
  deleted = false,
  onLine = true,
  localDB: IDBDatabase | null,
) {
  useEffect(() => {
    if (localDB === null) return;
    if (notesList.length === 0) return;
    if (deleted === true) return;
    if (onLine && deleted) return;
    console.log('dump to local db', notesList);
    const trans = localDB.transaction('notes', 'readwrite');
    const objectStore = trans.objectStore('notes');
    objectStore.clear();
    notesList.forEach((item) => objectStore.put(item));
  }, [onLine, deleted, notesList, localDB]);
}

