import React from 'react';
import './Highlight.css';

type HighlightProps = {
  text: string,
  search: string,
};

function isValidUrl(string: string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

export function Highlight({ text, search }: HighlightProps) {
  // Split text on highlight term, include term itself into parts, ignore case
  // if (search === '') return <>{text}</>;
  const string = search === '' ? '(http[s]?://[^ ,.;\'"`][\\S]+)' : `(http[s]?://[^ ,.;'"\`][\\S]+|${search})`;
  // const string = `(http[s]?://[^ ]*|${search})`;
  const regexp = new RegExp(string, 'gi');
  const regexpSearch = new RegExp(search, 'i');
  const regexpLink = new RegExp('http[s]?://[\\S]+', 'i');
  const parts = text.split(regexp);
  let counter = 0;
  const list = parts.map((x) => {
    counter += 1;
    switch (true) {
      case regexpLink.test(x) && isValidUrl(x):
        new URL(x); return <a key={counter} className='link' href={x} target='_blank' rel='noreferrer'>{x}</a>;
      case search !== '' && regexpSearch.test(x):
        return <span key={counter} className='highlighted'>{x}</span>;
      default: return <span key={counter}>{x}</span>;
    }
  });

  return <>{list}</>;
}