import React from 'react';
import { DeleteOutline, RestoreFromTrash, KeyboardDoubleArrowUp, EditOutlined } from '@mui/icons-material';

import { deleteNote, expandNote, undeleteNote, raiseNote } from './notesUtils';

type Note = {
  id: number;
  header: string;
  body: string;
}

type NoteToolbarProps = {
  active: boolean;
  // isMobile: boolean;
  deleted: boolean;
  token: string;
  noteId: number;
  dispatchNotesList: React.Dispatch<{ type: string,  payload: Note | number, }>
  dispatchExpand: React.Dispatch<{ type: string, payload: number | null }>
  // dispatchDraggable: React.Dispatch<{ type: string, payload: boolean }>
};

export function NoteToolbar({ active, deleted, token, noteId, dispatchNotesList, dispatchExpand }: NoteToolbarProps) {

  if (!active) return null;
  
  return deleted ?
    (
      <div className='note-toolbar'>
        <button
          className='toolbar-item'
          title='restore note from trash'
          onClick={undeleteNote(token, noteId, dispatchNotesList)}
        >
          <RestoreFromTrash className='restore-icon toolbar-icon' />
        </button>
      </div>
    ) : (
      <div className='note-toolbar'>
        <button
          className='toolbar-item'
          title='delete note'
          onClick={deleteNote(token, noteId, dispatchNotesList, dispatchExpand)}
        >
          <DeleteOutline className='delete-icon toolbar-icon' />
        </button>

        <button
          className='toolbar-item'
          title='raise note'
          onClick={raiseNote(token, noteId, dispatchNotesList)}
        >
          <KeyboardDoubleArrowUp className='raise toolbar-icon' />
        </button>
        
        <button
          className='toolbar-item'
          title='expand and edit note'
          onClick={expandNote(noteId, dispatchExpand)}
        >
          <EditOutlined className='expand-icon toolbar-icon' />
        </button>
      </div>
    );
}