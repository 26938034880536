import React, { useState } from 'react';
import './Login.css';
import { signup, resetForm, logInForm } from './uUtils';
import { actions } from '../reducers';

type SignUpProps = {
  dispatchAuth: React.Dispatch<{ type: string, payload: number | null }>,
};

export function SignUp ({ dispatchAuth }: SignUpProps) {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (field) {
      case 'name': setUserName(e.target.value); break;
      case 'psw': setPassword(e.target.value); break;
      default: return;
    }
  };

  async function signUpUser(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      e.stopPropagation();
      setMessage('');
      await signup(userName, password);
      dispatchAuth(actions.showLogInUser(1));
    } catch (err: any) {
      console.log(err.message);
      switch (err.message) {
        case 'duplicate_user': setMessage('An account with this email already exists'); break;
        default: setMessage('Try again please');
      }
    }
  }
  
  return (
    <div className='login'>
      <div className="wrapper">
        <div className="block-request">
          <h2>Sign up to Notes for free</h2>
          <form
            className='form'
            onSubmit={signUpUser}
          >
            <div className="input text-input">
              <div className="fieldset">
                <span className="title">Email address</span>
                <input
                  className='text'
                  required={true}
                  title='Your email'
                  placeholder='name@example.com'
                  name='name'
                  value={userName}
                  type='email'
                  onChange={handleChange('name')}
                />
              </div>
              <div className="fieldset">
                <span className="title">Set up password</span>
                <input
                  className='text'
                  required={true}
                  title='Set up password'
                  placeholder=''
                  name='psw'
                  value={password}
                  type='password'
                  onChange={handleChange('psw')}
                />
              </div>
            </div>
            <div className="input block-submit">
              <input
                className='submit request-submit'
                name='request-submit'
                value='Sign up'
                type='submit'
              />
              <div className='extra-actions'>
                <button
                  className="register"
                  title="Sign up"
                  onClick={logInForm(dispatchAuth)}
                >Log in</button>
                <button
                  className="forget"
                  title="forget password"
                  onClick={resetForm(dispatchAuth)}
                >Forget password?</button>
              </div>
              <div className='error-message'>
                {message}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}