import React, { useReducer } from 'react';
import { NoteContent } from './NoteContent';
import { NoteToolbar } from './NoteToolbar';
import { booleanReducer } from '../reducers';
import { setActiveNote } from './notesUtils';

type NoteItem = {
  id: number;
  header: string;
  body: string;
}

type NoteProps = {
  onLine: boolean;
  active: boolean;
  cropAt: number;
  search: string;
  deleted: boolean;
  note: NoteItem;
  token: string;
  dispatchNotesList: React.Dispatch<{ type: string,  payload: NoteItem | number, }>
  dispatchExpand: React.Dispatch<{ type: string, payload: number | null }>
  dispatchActive: React.Dispatch<{ type: string, payload: number | null }>
};

export function Note({
  onLine,
  active,
  note,
  cropAt,
  search,
  deleted,
  token,
  dispatchNotesList,
  dispatchExpand,
  dispatchActive
}: NoteProps) {

  const noteId = note.id;

  const [isCropped, dispatchCropped] = useReducer(booleanReducer, false);
  // const [isDraggable, dispatchDraggable] = useReducer(booleanReducer, false);
  // const [isMobile, dispatchIsMobile] = useReducer(booleanReducer, false);

  let className = 'note-wrapper';
  if (active) className += ' active';
  if (deleted) className += ' deleted';

  return (
    <div
      className={className}
      onClick={active ? () => true : setActiveNote(noteId, dispatchActive)}
      data-id={noteId}
      // onTouchEnd={() => {dispatchIsMobile({ type: 'setup', payload: true })}}
      // draggable={isDraggable}
      // onDragEnd={() => dispatchDraggable({ type: 'setup', payload: false })}
      // onDragOver={(e) => e.preventDefault()}
      // onDrop={(e) => console.log(note.id)}
    >
      <NoteContent
        initData={note}
        search={search}
        cropAt={cropAt}
        isCropped={active ? false : isCropped}
        dispatchCropped={dispatchCropped}
      />
      {onLine && (<NoteToolbar
        // isMobile={isMobile}
        active={active}
        deleted={deleted}
        token={token}
        noteId={noteId}
        dispatchNotesList={dispatchNotesList}
        dispatchExpand={dispatchExpand}
        // dispatchDraggable={dispatchDraggable}
      />)}
    </div>
  );
}