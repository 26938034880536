import { Auth } from './Users/Auth';
import { AreaOfUser } from './Notes/AreaOfUser';
import { userReducer, dbReducer, booleanReducer, actions } from './reducers';
import React, { useReducer, useEffect } from 'react';
import { useKeepConnected } from './useKeepConnected';
import { useLocalDB } from './useLocalDB';


export default function App() {
  const params = (new URL(document.URL)).searchParams;
  const [user, dispatchSetUser] = useReducer(userReducer, undefined);
  const [localDB, dispatchLocalDB] = useReducer(dbReducer, null);
  const [onLine, dispatchOnLine] = useReducer(booleanReducer, navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      dispatchOnLine(actions.setup(navigator.onLine));
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, []);

  useLocalDB(dispatchLocalDB);

  // Keep user authenticated by token
  useKeepConnected(dispatchSetUser, localDB);

  // const userToken = localStorage.getItem('token') || null;
  // if (userToken && !user) return null;

  if (user === undefined) return null;


  // if user is not logged in or password reset was envoked
  if (!user || (params.has('name') && params.has('key'))) return (
    <>
      <Auth
        localDB={localDB}
        dispatchSetUser={dispatchSetUser}
        // login={logMeInByPassword}
        name={params.get('name') || null}
        resetKey={params.get('key' || null)}
      />
    </>
  );

  return (
    <>
      <AreaOfUser
        onLine={onLine}
        user={user}
        localDB={localDB}
        dispatchSetUser={dispatchSetUser}
      />
    </>
  );
}