import React, { useEffect } from 'react';

import setup from '../setup';
import { actions } from '../reducers';

const { usersAPI } = setup;

type User = {
  id: number,
  name: string,
  token: string,
};

export const loginByPassword = async (
  dispatch: React.Dispatch<{ type: string, payload: User | null | undefined}>,
  localDB: IDBDatabase | null,
  userName: string,
  password: string
) => {
  try {
    const response = await fetch(`${usersAPI}/login`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: userName, password}),
    });
    const result = await response.json();
    if (result.error) throw new Error(result.message);
    const { id, name, token } = result;
    const envStore = (localDB as IDBDatabase)
      .transaction(['env'], 'readwrite')
      .objectStore('env');
    envStore.delete('user');
    envStore.put({ name: 'user', value: result });
    // localStorage.setItem('token', token);
    // userToken = token;
    dispatch(actions.loginUser({ id, name, token }));
  } catch (err: any) {
    return Promise.reject(err);
  }
};

// reset password for existing user
export const reset = async (userName: string, password: string, resetKey: string) => {
  try {
    const response = await fetch(`${usersAPI}`, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: userName, password, key: resetKey }),
    });
    const result = await response.json();
    // console.log('result', result);
    if (result.error) throw new Error(result.message);
    return result;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const signup = async (userName: string, password: string) => {
  try {
    const response1 = await fetch(`${usersAPI}/key?name=${userName}`);
    const { key, error, message } = await response1.json();
    if (error) throw new Error(message);
    const response = await fetch(`${usersAPI}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: userName, password, key }),
    });
    const result = await response.json();
    // console.log('result', result);
    if (result.error) throw new Error(result.message);
    return result;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const makeKey = async (userName: string) => {
  try {
    const response = await fetch(`${usersAPI}/key`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: userName }),
    });
    const result = await response.json();
    // console.log('result', result);
    if (result.error) throw new Error(result.message);
    return result;
  } catch (err: any) {
    return Promise.reject(err);
  }
};


// export const fetchUserByToken = (
//   dispatch: React.Dispatch<{ type: string, payload: User | null | undefined}>
// ) => async(utoken: string) => {
//   try {
//     const url = `${usersAPI}?token=${utoken}`;
//     const response = await fetch(url);
//     const result = await response.json();
//     if (result.error) throw new Error(result.message);
//     const { id, name, token } = result;
//     dispatch(actions.loginUser({ id, name, token }));
//   } catch (err: any) {
//     console.log(err.message);
//     logout(dispatch)();
//   }
// };

export const logout = (
  dispatch: React.Dispatch<{ type: string, payload: User | null | undefined}>,
  localDB: IDBDatabase | null,
) =>{
  try {
    // console.log('remove_token');
    // localStorage.removeItem('token');
    // userToken = null;
    const usersStore = (localDB as IDBDatabase)
      .transaction(['env'], 'readwrite')
      .objectStore('env');
    usersStore.delete('user');
    dispatch(actions.loginUser(null));
  } catch (err: any) {
    console.log(err.message);
  }
};

export const signUpForm = (
  dispatchAuth: React.Dispatch<{
    type: string;
    payload: number | null;
  }>) => async (e: React.MouseEvent<HTMLElement>) => {
  e.preventDefault();
  e.stopPropagation();
  dispatchAuth(actions.showSignUpUser(2));
};

export const logInForm = (
  dispatchAuth: React.Dispatch<{
    type: string;
    payload: number | null;
  }>) => (e: React.MouseEvent<HTMLElement>) => {
  e.preventDefault();
  e.stopPropagation();
  dispatchAuth(actions.showLogInUser(1));
};

export const resetForm = (
  dispatchAuth: React.Dispatch<{
    type: string;
    payload: number | null;
  }>) => (e: React.MouseEvent<HTMLElement>) => {
  e.preventDefault();
  e.stopPropagation();
  dispatchAuth(actions.showResetPassword(3));
};

export const useReset = (
  name: string | null,
  resetKey: string | null,
  dispatchAuth: React.Dispatch<{ type: string, payload: number | null }>
) => {
  useEffect(() => {
    // if (!name || !resetKey) return;
    const f1 = async () => {
      try {
        if (name !== null && resetKey !== null) {
          const response = await fetch(`${usersAPI}/key-check?name=${name}&key=${resetKey}`);
          const result = await response.json();
          if (result.error) throw new Error('incorrect_key');

          dispatchAuth(actions.setupPassword(6));
        } else {
          dispatchAuth(actions.showLogInUser(1));
        }
      } catch (err) {
        dispatchAuth(actions.setupPassword(5));
      }
    };
    f1();
  }, [dispatchAuth, name, resetKey]);
};
