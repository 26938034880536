type Note = {
  id: number;
  header: string;
  body: string;
}

type User = {
  id: number,
  name: string,
  token: string,
}

export const actions = {
  // booleanReducer
  cropNote: () => ({ type: 'crop-note', payload: true }),
  setup: (payload: boolean) => ({ type: 'setup', payload }),
  toggle: () => ({ type: 'toggle', payload: true }),
  // stringReducer
  change: (payload: string) => ({ type: 'change', payload }),
  // numberReducer
  resizeScreen: (payload: number) => ({ type: 'resize-screen', payload }),
  expandNote: (payload: number) => ({ type: 'expand-note', payload }),
  unExpandNote: () => ({ type: 'unexpand-note', payload: null }),
  activateNote: (payload: number) => ({ type: 'activate-note', payload }),
  changeNoteId: (payload: number) => ({ type: 'change-note-id', payload }),
  deactivateNote: (payload: number) => ({ type: 'deactivate-note', payload }),
  showSignUpUser: (payload: number) => ({ type: 'show-sign-up', payload }),
  showLogInUser: (payload: number) => ({ type: 'show-log-in', payload }),
  showResetPassword: (payload: number) => ({ type: 'show-reset-password', payload }),
  setupPassword: (payload: number) => ({ type: 'setup-password', payload }),

  // notesListReducer
  resetNotes: (payload: Note[]) => ({ type: 'reset-notes', payload }),
  searchNotes: (payload: string) => ({ type: 'search-notes', payload }),
  appendNotes: (payload: Note[]) => ({ type: 'append-notes', payload }), // rename items -> notes
  editNote: (payload: Note) => ({ type: 'edit-note', payload }),
  addNote: (payload: Note) => ({ type: 'add-note', payload }),
  raiseNote: (payload: number) => ({ type: 'raise-note', payload }),
  deleteNote: (payload: number) => ({ type: 'delete-note', payload }),
  // userReducer
  loginUser: (payload: User | null | undefined) => ({ type: 'login-user', payload }),
  // dbReducer
  openDB: (payload: IDBDatabase) => ({ type: 'open-local-db', payload }),
};

export function numbersReducer(state: number | null, action: { type: string, payload: number | null }) {
  switch (true) {
    case action.type === 'resize-screen': return action.payload;
    case action.type === 'change-note-id': return action.payload;
    case action.type === 'expand-note': return action.payload;
    case action.type === 'unexpand-note': return null;
    case action.type === 'activate-note': return action.payload;
    case action.type === 'show-sign-up': return action.payload;
    case action.type === 'show-log-in': return action.payload;
    case action.type === 'show-reset-password': return action.payload;
    case action.type === 'setup-password': return action.payload;
    case action.type === 'deactivate-note': return null;
    default: return state;
  }
}

export function userReducer(state: User | null | undefined, action: { type: string, payload: User | null | undefined }) {
  switch (true) {
    case action.type === 'login-user': return action.payload;
    default: return state;
  }
}

export function dbReducer(state: IDBDatabase | null, action: { type: string, payload: IDBDatabase }) {
  switch (true) {
    case action.type === 'open-local-db': return action.payload;
    default: return state;
  }
}

export function notesListReducer(state: Note[], action: { type: string, payload: Note[] | Note | number | string }) {
  switch (true) {
    case action.type === 'reset-notes': return action.payload as Note[];
    case action.type === 'append-notes': return [...state, ...action.payload as Note[]];
    case action.type === 'search-notes':
      return [...state.filter((x) => (x.body + x.header).toLowerCase().includes((action.payload as string).toLowerCase()))];
    case action.type === 'edit-note': return state.map((x) => {
      if (x.id === (action.payload as Note).id) return (action.payload) as Note;
      return x;
    });
    case action.type === 'raise-note': return [...state.filter((x) => x.id === action.payload), ...state.filter((x) => x.id !== action.payload)];
    case action.type === 'add-note': return [action.payload as Note, ...state];
    case action.type === 'delete-note': return [...state].filter((x) => x.id !== action.payload);
    default: return state;
  }
}

export function booleanReducer(state: boolean, action: { type: string, payload: boolean }) {
  switch (true) {
    case action.type === 'crop-note': return true;
    case action.type === 'setup': return action.payload;
    case action.type === 'toggle': return !state;
    default: return state;
  }
}

export function stringReducer(state: string, action: { type: string, payload: string }) {
  switch (true) {
    case action.type === 'change': return action.payload;
    default: return state;
  }
}

// export function layoutReducer(state: Layout, action: { type: string, numColumns: number}) {
//   switch (true) {
//     case action.type === 'resize': return { ...state, numColumns: action.numColumns };
//     default: return state;
//   }
// }





