import React, { useEffect, useReducer, useRef } from 'react';
import { DeleteOutline, CloseOutlined, SaveOutlined } from '@mui/icons-material';
import { booleanReducer, numbersReducer, stringReducer } from '../reducers';

import { saveNote, saveAndCloseNote, deleteNote, getNoteById } from './notesUtils';
import { actions } from '../reducers';

type Note = {
  id: number;
  header: string;
  body: string;
}

type EditableProps = {
  initialItem: Note;
  token: string;
  dispatchNotesList: React.Dispatch<{ type: string, payload: Note | number}>
  dispatchExpand: React.Dispatch<{ type: string, payload: number | null }>
};

export function Editable({ initialItem, token, dispatchExpand, dispatchNotesList } : EditableProps) {

  // const [item, dispatchEditableItem] = useReducer(editableItemReducer, initialItem);
  const [header, dispatchHeader] = useReducer(stringReducer, initialItem.header);
  const [body, dispatchBody] = useReducer(stringReducer, initialItem.body);
  const [id, dispatchId] = useReducer(numbersReducer, initialItem.id);
  const [changed, dispatchChanged] = useReducer(booleanReducer, false);

  const item :Note = { id: id || 0, header, body };

  const changeHandler = (elem: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (refHeader.current === null || refBody.current === null) return;
    switch (elem) {
      case 'header': dispatchHeader(actions.change(e.target.value)); break;
      case 'body': dispatchBody(actions.change(e.target.value)); break;
    }
    dispatchChanged(actions.setup(true));
  };

  const refHeader = useRef<HTMLTextAreaElement>(null);
  const refBody = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    async function f1() {
      try {
        if (id === 0) return;
        const noteFromDB = await getNoteById(token, id as number);
        dispatchHeader(actions.change(noteFromDB.header));
        dispatchBody(actions.change(noteFromDB.body));
      } catch (err: any) {
        console.log(err.message);
      }
    } 
    f1();
  }, [id, token]);
  
  useEffect(() => {
    if (refHeader.current === null) return;
    refHeader.current.style.height = 0 + 'px';
    const scrollHeight = refHeader.current.scrollHeight;
    refHeader.current.style.height = scrollHeight + 'px';
  }, [header]);

  useEffect(() => {
    if (refBody.current === null) return;
    refBody.current.style.height = '0';
    const scrollBody = refBody.current.scrollHeight;
    refBody.current.style.height = scrollBody + 'px';
  }, [body]);

  if (initialItem === undefined) return (
    <div className='editable-block error'>
      Something went wrong. Try again please
    </div>
  );

  return (
    <div className='editable-block'>
      <div className='toolbar-wrapper'>
        <div className='note-toolbar'>
          <button
            className='toolbar-item active'
            title='delete note'
            onClick={deleteNote(token, item.id, dispatchNotesList, dispatchExpand)}
          >
            <DeleteOutline className='delete-icon toolbar-icon' />
          </button>

          <button
            className='toolbar-item active'
            title='save note'
            onClick={saveNote(token, item, changed, dispatchNotesList, dispatchExpand, dispatchId)}
          >
            <SaveOutlined
              className='save-icon toolbar-icon' />
          </button>

          <button
            className='toolbar-item active'
            title='save and close note'
            onClick={saveAndCloseNote(token, item, changed, dispatchNotesList, dispatchExpand, dispatchId)}
          >
            <CloseOutlined className='close-icon toolbar-icon' />
          </button>
        </div>
      </div>
      <textarea
        className='textarea-header textarea'
        ref={refHeader}
        value={header}
        // style={{ ...textareaStyle, fontWeight: 'bold' }}
        placeholder='Title'
        onChange={changeHandler('header')}
      />
      <textarea
        className='textarea-body textarea'
        ref={refBody}
        value={body}
        // style={{ ...textareaStyle }}
        placeholder='Take a note'
        onChange={changeHandler('body')}
      />
    </div>
  );
}